import * as React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import Table, {
	ColumnProps,
	TablePaginationConfig,
} from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';
import Search from 'antd/lib/input/Search';
import Dropdown from 'antd/lib/dropdown';
import Button from 'antd/lib/button';
import {
	CopyOutlined,
	DeleteOutlined,
	EditOutlined,
	MoreOutlined,
} from '@ant-design/icons';
import Space from 'antd/lib/space';

import {
	listWithModal,
	listWithModalHOCProps,
} from '@common/react/components/List/ListWithModal/ListWithModal';
import { member } from '@common/react/utils/decorators';
import { ItemsState } from '@common/react/store/ItemList';
import { Nullable } from '@common/typescript/objects/Nullable';
import TableBuilder from '@common/react/utils/Helpers/TableBuilder';
import { TableController, TableControls } from '@common/react/smart components/Table/TableController';

import {
	Price,
	PriceKind,
	PriceType,
} from '@app/objects/Price';
import {
	ExtendableItemsPage,
	itemsPageMapDispatchToProps,
	ItemsPageReduxActions,
	ItemsPageReduxProps,
} from '@app/components/Pages/ItemsPage';
import { ServiceType } from '@app/objects/Pet';
import { ApplicationState } from '@app/store';
import { PriceEditor } from '@app/components/Various/PriceEditor/PriceEditor';
import { ActionBuilder } from '@app/components/Utils/ActionFactory';
import { SpecialServiceType } from '@app/objects/SpecialService';
import { LocalSelect } from '@app/components/UI/Inputs/LocalSelect';
import { priceKindClinicOptions, priceKindOptions } from '@app/components/UI/Inputs/LocalSelectOptions';
import { ActionButtons } from '@app/components/UI/Buttons/ActionButtons';
import { columns } from '@app/components/Various/PriceList/columns';
import { WeightUnits } from '@app/objects/Crematory';
import { AdaptiveAddButton } from '@app/components/UI/Buttons/AdaptiveButtons/AdaptiveAddButton';
import { MessageType, alertMessage } from '@app/utilities/alert';
import { request } from '@app/components/Api';
import { deleteConfirmation } from '@app/components/UI/Modal/DeleteConfirmation';
import { CopyPrices, copyPricesModalId } from '@app/components/Various/PriceList/CopyPrices';
import { MultipleEdit, multipleEditModalId } from '@app/components/Various/PriceEditor/MultipleEdit';
import { decodeSearchParams, encodeSearchParams, getPageFilters } from '@app/components/Utils/Utils';
import { SingleTableOptions, toQueryString } from '@app/components/UI/CrematoryTables/CrematoryTables';

const headStyle = { display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', margin: '0 -12px' };
export interface OwnProps {
	data: SingleTableOptions;
	crematoryId: number;
	clinicId: Nullable<number>;

	priceType: PriceType;
	serviceType: ServiceType;

	weightUnit?: WeightUnits;
	storeName: keyof ApplicationState;
	readOnly?: boolean;

	headerControls?: JSX.Element | null;
}

type Props = OwnProps & listWithModalHOCProps & WithTranslation;

interface State {
	priceKind: PriceKind;
	query?: string;
	needRefreshSelect: boolean;
	filters: Record<string, unknown>;
	currentPage?: number;
	deleteLoading: boolean;
}

interface AdditionalParams {
	priceType: PriceType;
	serviceType: ServiceType;
	withSpecialService: boolean;
	withPetSpecie: boolean;
	withInventoryItem: boolean;
	clinicId?: Array<number | null>;
	onlyCrematory: boolean;
	filters: {
		specialServiceTypes: Array<Nullable<SpecialServiceType>>;
		priceKind: Array<PriceKind>;
	};
}

function getPrefix(data: SingleTableOptions) {
	const price = PriceType[data.price].toLowerCase();
	const service = toQueryString(ServiceType[data.service]);

	return `price-${price}=${service}`;
}

function getFilters(search: string, data: SingleTableOptions) {
	if (search.includes(getPrefix(data))) return getPageFilters(decodeSearchParams(search));

	return {};
}

function getPage(search: string, data: SingleTableOptions) {
	if (search.includes(getPrefix(data))) {
		const params = new URLSearchParams(search);
		const page = params.get('page');

		return Number(page ?? 1);
	}

	return 1;
}
class PriceListInner extends ExtendableItemsPage<Price, Props, State> {
	public state: State = {
		priceKind: getFilters(this.props.location.search, this.props.data).priceKind?.[0] ?? PriceKind.BasePrice,
		needRefreshSelect: false,
		filters: getFilters(this.props.location.search, this.props.data),
		query: getFilters(this.props.location.search, this.props.data).name,
		currentPage: getPage(this.props.location.search, this.props.data),
		deleteLoading: false,
	};

	public type: string = 'price';
	public store: keyof ApplicationState = this.props.storeName;
	public path: string = 'priceList';
	public deletePath: Nullable<string> = 'deletePrice';
	public listPath: string = 'pet-types';
	public editorCaption: string = 'Add price';
	public caption: string = ' ';
	public tableClassName: string = 'table-mobile table-with-buttons small-padding-table hide-checkbox-title table--row-click';
	public isFilterText: boolean = false;

	public additionalParams: AdditionalParams = {
		priceType: this.props.priceType,
		serviceType: this.props.serviceType,
		withSpecialService: true,
		withPetSpecie: true,
		withInventoryItem: true,
		clinicId: [this.props.clinicId ?? null],
		onlyCrematory: !this.props.clinicId,
		filters: {
			specialServiceTypes: [null, SpecialServiceType.Service, SpecialServiceType.Kit],
			priceKind: [PriceKind.BasePrice],
			...this.state.filters,
		},
	};

	componentDidMount(): void {
		const params = {
			page: this.state.currentPage,
			count: this.count,
			...this.additionalParams,
		};

		this.props.actions.reqPages(this.store, this.path, this.type, params)
			.then(this.onItemsLoaded)
			.catch(this.onItemsError);
	}

	componentDidUpdate(prevProps): void {
		const prevSearchParams = new URLSearchParams(prevProps.location.search);
		const newSearchParams = new URLSearchParams(this.props.location.search);

		if (prevProps.location.search !== this.props.location.search
			&& prevSearchParams.get('page') === newSearchParams.get('page')
			&& this.props.location.search.includes(getPrefix(this.props.data))) {
			const filters = getFilters(this.props.location.search, this.props.data);
			const page = getPage(this.props.location.search, this.props.data);
			const priceKind = filters.priceKind?.[0] ?? PriceKind.BasePrice;

			this.setState({
				priceKind,
				query: filters.name ?? '',
				filters: { ...this.state.filters, ...filters, priceKind: [priceKind] },
				currentPage: page,
			}, () => this.onFilterSearch());
		} else if (
			(prevProps.location.search !== this.props.location.search
			&& !this.props.location.search
			&& prevProps.priceType === this.props.data.price
			&& prevProps.serviceType === this.props.data.service)) {
			this.setState({
				priceKind: PriceKind.BasePrice,
				query: '',
				filters: {},
				currentPage: 1,
			}, () => this.onFilterSearch());
		}
	}

	getColumns(readOnly: boolean): Array<ColumnProps<Price>> {
		const { priceKind } = this.state;
		const currentColumns: Array<ColumnProps<Price>> = columns(this.props.weightUnit ?? WeightUnits.Pounds)[priceKind];

		return [
			...currentColumns,
			...TableBuilder.shape<Price>()
				.addColumn({
					title: '',
					dataIndex: 'actions',
					render: (text, record) => (
						<ActionButtons
							actions={this.getActionColumns(record)}
							itemName={record.specialServiceId !== null && record.specialServiceId > 0 ? 'service' : 'price'}
						/>
					),
				}, !readOnly).build(),
		];
	}

	@member
	openAddDialog(priceKind: number) {
		this.setState({
			priceKind,
		});
		this.props.openDialog(-1);
	}

	getParameters(page: number = 1) {
		return {
			page,
			...this.additionalParams,
			filters: {
				priceKind: [this.state.priceKind],
				specialServiceTypes: [
					null,
					SpecialServiceType.Kit,
					SpecialServiceType.Service,
				],
				...this.state.filters,
			},
		};
	}

	getActionColumns(record: Price) {
		if (this.props.clinicId && record.priceKind === PriceKind.Discount) {
			return new ActionBuilder()
				.addDeleteWithRedux(record, (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
					e?.stopPropagation();
					if (e) this.handleDelete(e, record, () => this.setState({ needRefreshSelect: true }));
				})
				.build();
		}

		return new ActionBuilder()
			.addEdit(() =>	{
				this.props.openDialog(record.id);
				this.setState({
					priceKind: record.priceKind,
				});
			})
			.addDeleteWithRedux(record, (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
				e?.stopPropagation();
				if (e) this.handleDelete(e, record, () => this.setState({ needRefreshSelect: true }));
			})
			.build();
	}

	handleTableChange(pagination: TablePaginationConfig) {
		this.setState({ currentPage: pagination.current }, () => this.onUpdateUrl());
		this.props.actions.reqPages(
			this.store,
			this.path,
			this.type,
			this.getParameters(pagination.current),
		);
	}

	handleDeletePrice(rowSelection) {
		this.setState({ deleteLoading: true });

		return request('deletePrice', { id: rowSelection.selectedRowKeys })
			.then(() => {
				rowSelection.onChange([]);
				this.props.actions.refreshPages(
					this.store,
					this.path,
					this.getParameters(),
				);
				this.setState({ needRefreshSelect: true });
				alertMessage(MessageType.success, 'Success! The prices was deleted.');
			})
			.catch((error: string) => alertMessage(MessageType.error, error))
			.finally(() => this.setState({ deleteLoading: false }));
	}

	@member
	onSave(page?: number) {
		// TODO: optimize: update works fine, but adding new item doesn't work; Has to refresh page for now
		this.props.actions.refreshPages(
			this.store,
			this.path,
			this.getParameters(page),
		);
		this.props.closeDialog();
	}

	onFilterSearch() {
		this.props.actions.refreshPages(
			this.store,
			this.path,
			this.getParameters(),
		);
	}

	getPriceTypeNames() {
		if (this.props.clinicId) return priceKindClinicOptions;

		return priceKindOptions;
	}

	@member
	onUpdateUrl() {
		const prefix = getPrefix(this.props.data);
		const searchParams = encodeSearchParams(this.state.filters);
		const search = searchParams !== '' ? `${prefix}&${searchParams}` : prefix;

		this.props.history.push({ search: `${search}&page=${this.state.currentPage}` });
	}

	public render() {
		const {
			crematoryId,
			visible,
			closeDialog,
			modalItemId,
			priceType,
			readOnly = false,
			clinicId,
			serviceType,
			headerControls = null,
			t,
		} = this.props;

		const { items, pagination, isLoading } = this.props.items;
		const columns = this.getColumns(readOnly);
		const {
			priceKind, currentPage, needRefreshSelect, deleteLoading,
		} = this.state;
		const priceTypeOptions = this.getPriceTypeNames();

		return (
			<TableController>
				{({ rowSelection }: TableControls<Price>) => (
					<>
						<div className="site-headline site-headline_with-button clearfix hide-print" style={{ marginTop: '15px' }}>
							{headerControls}

							<form style={headStyle}>
								<div className="is-relative col-sm-12">
									<LocalSelect
										value={priceKind}
										onChange={(value: number) => {
											this.setState({
												currentPage: 1,
												priceKind: value,
												query: '',
												filters: { ...this.state.filters, priceKind: [value] },
											}, () => {
												this.onFilterSearch();
												rowSelection.onChange([]);
												this.onUpdateUrl();
											});
										}}
										options={priceTypeOptions}
										deselectType=""
										filterName="priceKind"
										fieldName=""
										placeholder="Select the Type Of Price"
										allowClear={false}
									/>
								</div>

								<div
									className="pull-right site-subheadline"
									style={{
										paddingRight: '15px',
										justifySelf: 'end',
										display: 'flex',
										columnGap: '10px',
									}}
								>
									{
										!readOnly && (
											<Space.Compact>
												<AdaptiveAddButton
													itemName="Price"
													type="primary"
													ghost
													withIcon
													onClick={() => this.openAddDialog(priceKind)}
													disabled={priceKind === PriceKind.RushFee && items.length !== 0}
												/>
												<Dropdown
													menu={{
														items: [
															{
																key: 'copy',
																label: (
																	<Button
																		type="link"
																		icon={<CopyOutlined />}
																		size="small"
																		onClick={() => this.props.openDialog(copyPricesModalId)}
																	>
																		Copy Prices
																	</Button>
																),
															},
															{
																key: 'edit',
																label: (
																	<Button
																		type="link"
																		icon={<EditOutlined />}
																		size="small"
																		className="color-blue"
																		disabled={!rowSelection.selectedRowKeys.length}
																		onClick={() => this.props.openDialog(multipleEditModalId)}
																	>
																		Edit {rowSelection.selectedRowKeys.length ? `${rowSelection.selectedRowKeys.length} item(s)` : null}
																	</Button>
																),
															},
															{
																key: 'delete',
																label: (
																	<Button
																		type="link"
																		icon={<DeleteOutlined />}
																		size="small"
																		disabled={!rowSelection.selectedRowKeys.length}
																		loading={deleteLoading}
																		className="color-red"
																		onClick={() => deleteConfirmation({
																			title: `${rowSelection.selectedRowKeys.length} item(s)`,
																			callback: () => this.handleDeletePrice(rowSelection),
																			t,
																		})}
																	>
																		{`Delete ${rowSelection.selectedRowKeys.length ? `${rowSelection.selectedRowKeys.length} item(s)` : ''}`}
																	</Button>
																),
															},
														],
													}}
													placement="bottomRight"
												>
													<Button
														type="primary"
														ghost
														style={{ borderLeft: 'none' }}
														icon={<MoreOutlined />}
													/>
												</Dropdown>
											</Space.Compact>
										)
									}
								</div>
							</form>
						</div>

						<form id="price-filters-form">
							<Search
								allowClear
								value={this.state.query}
								onChange={(event) => {
									if (event.target.value === '' && event.type === 'click') {
										this.setState((s) => ({
											...s,
											currentPage: 1,
											filters: { ...s.filters, name: undefined },
											query: undefined,
										}), () => this.onUpdateUrl());
									} else {
										this.setState({ query: event.currentTarget.value });
									}
								}}
								placeholder="Search by Name"
								onSearch={(value: string, event) => {
									event?.preventDefault();
									event?.stopPropagation();

									this.setState({ currentPage: 1, filters: { ...this.state.filters, name: value } }, () => {
										this.onFilterSearch();
										this.onUpdateUrl();
									});
								}}
								style={{ padding: '0 0 10px' }}
								enterButton
							/>
						</form>

						<Table
							columns={columns}
							dataSource={items}
							pagination={{ ...pagination, hideOnSinglePage: true }}
							loading={isLoading}
							onChange={this.handleTableChange}
							rowKey="id"
							className={this.tableClassName}
							rowSelection={rowSelection as TableRowSelection<Price>}
							onRow={(record: Price) => {
								const isSelect = rowSelection.selectedRowKeys.includes(record.id);

								return {
									selectedRowKeys: rowSelection.selectedRowKeys,
									onClick: (event) => {
										event.preventDefault();

										if (!isSelect) {
											rowSelection.onChange([...rowSelection.selectedRowKeys, record.id]);
										} else {
											rowSelection.onChange(rowSelection.selectedRowKeys.filter((item) => item !== record.id));
										}
									},

								};
							}}
						/>
						<PriceEditor
							crematoryId={crematoryId}
							serviceType={serviceType}
							clinicId={clinicId}
							priceId={Number(modalItemId)}
							priceType={priceType}
							priceKind={priceKind}
							onSave={() => this.onSave(currentPage)}
							closeDialog={closeDialog}
							modalItemId={modalItemId}
							visible={visible}
							item={items.find((item) => item.id === modalItemId) ?? null}
							needRefresh={needRefreshSelect}
							setNeedRefresh={(value: boolean) => this.setState({ needRefreshSelect: value })}
						/>

						<CopyPrices
							closeDialog={closeDialog}
							modalItemId={modalItemId}
							currentPriceType={priceType}
							currentServiceType={serviceType}
							priceKind={priceKind}
							clinicId={clinicId}
							reload={() => this.onSave(currentPage)}
						/>
						<MultipleEdit
							items={items}
							priceKind={priceKind}
							modalItemId={modalItemId}
							rowSelection={rowSelection}
							closeDialog={closeDialog}
							reload={() => this.onSave(currentPage)}
						/>
					</>
				)}
			</TableController>
		);
	}
}

export const PriceList = connect<ItemsPageReduxProps<Price>, ItemsPageReduxActions<Price>, OwnProps, ApplicationState>(
	(state: ApplicationState, ownProps: OwnProps) => ({
		items: { ...state[ownProps.storeName] as ItemsState<Price> },
	}),
	itemsPageMapDispatchToProps,
)(withTranslation()(listWithModal(PriceListInner)));
